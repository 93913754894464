<template>
  <a-layout class="main-container">
    <home-nav />
    <div class="divider"></div>
    <div class="contact-container">
      <div class="contact-title">联系</div>
      <img
        src="@/assets/contact/contact-en.png"
        alt=""
        class="contact-title-en"
      />
      <div class="contact-divider"></div>
      <div class="association-name">中国少年儿童造型艺术学会</div>
      <div class="association-name-en">
        CHINESE PLASTIC ARTS ASSOCIATION FOR CHILDREN
      </div>
      <p>学会官网：https://www.cacart.org.cn/</p>
      <p>邮箱：caca_1992@163.com</p>
      <p class="space">地址：北京市东城区灯市口大街33号国中商业大厦</p>

      <p>赛事合作请联系：15868495613</p>
      <p>研学合作请联系：15868495613</p>
      <p>课程合作请联系：15868495613</p>
      <p>其他合作请联系：15868495613</p>

      <img src="@/assets/contact/back-img1.png" alt="" class="back-img1" />
      <img src="@/assets/contact/back-img2.png" alt="" class="back-img2" />
    </div>
  </a-layout>
</template>

<script>
import HomeNav from "@/components/HomeNav.vue";

export default {
  components: { HomeNav },
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
.main-container {
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;
  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.06);
  }
  .contact-container {
    width: 1150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .contact-title {
      font-weight: 500;
      font-size: 21px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 2px;
      margin: 51px 0 8px 0;
    }
    .contact-title-en {
      width: 126px;
      height: 42px;
    }
    .contact-divider {
      width: 180.52px;
      height: 2px;
      background-color: #222423;
      margin: 32px 0 19px 0;
    }
    .association-name {
      font-weight: 500;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 6px;
    }
    .association-name-en {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 1px;
      margin-bottom: 52px;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 1px;
    }
    .space {
      margin-bottom: 60px;
    }
    .back-img1 {
      position: absolute;
      left: 72px;
      top: 188px;
      width: 144px;
      height: 144px;
    }
    .back-img2 {
      position: absolute;
      right: 54px;
      bottom: 95px;
      width: 144px;
      height: 144px;
    }
  }
}
</style>
